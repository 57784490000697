<template>
  <vue-final-modal
    class="modal-loyalty-earn"
    :overlay-transition="{ mode: 'in-out', duration: 250 }"
    :content-transition="{ mode: 'in-out', duration: 250 }"
  >
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title" />

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('loyalty-earn')" />
      </div>
    </div>

    <div class="vfm__body">
      <atomic-image
        class="modal-loyalty-earn__img"
        :src="getContent(props.currentLocaleData, props.defaultLocaleData, 'image')"
      />

      <div class="modal-loyalty-earn__title">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'title') }}
      </div>

      <div class="modal-loyalty-earn__description">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'description') }}
      </div>

      <div class="modal-loyalty-earn__steps">
        <div v-for="(step, index) in steps" :key="index" class="modal-loyalty-earn__steps-item">{{ step }}</div>
      </div>

      <button-base type="primary" size="md" @click.once="handleConfirm">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'buttonLabel') }}
      </button-base>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import type { CIModalsContent } from '~/types';

  const props = defineProps<{
    currentLocaleData: Maybe<CIModalsContent['loyaltyEarn']>;
    defaultLocaleData: Maybe<CIModalsContent['loyaltyEarn']>;
  }>();

  const { openWalletModal, closeModal } = useModalStore();
  const { getContent, localizePath } = useProjectMethods();
  const profileStore = useProfileStore();
  const { isLoggedIn } = storeToRefs(profileStore);
  const router = useRouter();

  const steps = computed(() => {
    const contentSteps: { stepText: string }[] | undefined = getContent(
      props.currentLocaleData,
      props.defaultLocaleData,
      'steps'
    );
    if (contentSteps) return contentSteps.map(step => step.stepText);
    return [];
  });

  const handleConfirm = async (): Promise<void> => {
    if (isLoggedIn.value) await openWalletModal('deposit');
    else await router.push(localizePath('/sign-up'));
    await closeModal('loyalty-earn');
  };
</script>

<style src="~/assets/styles/components/modal/loyalty-earn.scss" lang="scss" />
